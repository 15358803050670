<template>
  <section id="whoami" class="hero is-small is-primary is-bold">
    <div class="hero-body">
      <div class="container">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img
                class="is-rounded"
                src="@/assets/Rowdy_Chotkan_128x128.png"
              />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Rowdy Chotkan</strong>
                <br />
                Dear readers, welcome. My name is Rowdy Chotkan. Currently, I am
                doing my PhD in Computer Science at TU Delft. My research dives
                into digital identity and trustworthy online communities.
                <br />
                <small> Posted 2020 · Updated 21 Oct. 2022 </small>
              </p>
            </div>

            <article class="media">
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Rowdy Chotkan</strong>
                    <br />
                    My interests lay in Cyber Security, Distributed Ledgers and
                    Self-Sovereign Identity (SSI). Currently, I am researching
                    Distributed Autonomous Organizations (DAOs).
                    <br />
                    <small>Posted 2020 · Updated 21 Oct. 2022</small>
                  </p>
                </div>
              </div>
              <figure class="media-right">
                <p class="image is-48x48">
                  <img
                    class="is-rounded"
                    src="@/assets/Rowdy_Chotkan_128x128.png"
                  />
                </p>
              </figure>
            </article>

            <article class="media">
              <figure class="media-left">
                <p class="image is-48x48">
                  <img
                    class="is-rounded"
                    src="@/assets/Rowdy_Chotkan_128x128.png"
                  />
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>Rowdy Chotkan </strong>
                    <br />
                    Feel free to reach out. You may always ask for a copy of my
                    CV. Reach out to me on my listed socials or on
                    <a href="mailto:info@rowdy.me">info@rowdy.me</a>.
                    <br />
                    <small> Posted 2020 · Updated 21 Oct. 2022 </small>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "whoAmI2",
  components: {},
  data() {
    return {
      message: "",
    };
  },
};
</script>

<style></style>
