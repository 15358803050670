
import Vue from "vue";
import Card from "@/components/Card.vue";

export default Vue.extend({
  name: "timeline2-component",
  components: {
    Card,
  },
  data() {
    return {};
  },
  methods: {},
});
