
import { Component, Prop, Vue } from "vue-property-decorator";
import Notification from "@/components/intro/Notification.vue";
import Scroller from "@/components/general/Scroller.vue";
import ScrollReveal from "scrollreveal";
import "particles.js";

@Component({
  components: {
    Notification,
    Scroller,
  },
})
export default class Intro extends Vue {
  @Prop() private msg!: string;
  @Prop() private menuEnabled?: boolean;
  @Prop() private construction?: boolean;
  @Prop() private disabledParticles?: boolean;

  isMenuEnabled = this.menuEnabled || false;
  isImageModalActive = false;

  mounted() {
    this.scrollRevealCalls();
    this.initParticles();
  }

  initParticles() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).particlesJS("particles-js", {
      particles: {
        number: {
          value: 75,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 45,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#a28d8d",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 4,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "window",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: false,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }

  scrollRevealCalls() {
    ScrollReveal().reveal(".welcome", {
      delay: 300,
      distance: "100px",
      origin: "top",
      reset: true,
    });
    ScrollReveal().reveal(".slogan", {
      delay: 800,
      distance: "100px",
      origin: "left",
      reset: true,
    });
    ScrollReveal().reveal(".slogan-2", {
      delay: 1500,
      distance: "100px",
      origin: "bottom",
      reset: true,
    });
    ScrollReveal().reveal(".externals", {
      delay: 1000,
      distance: "100px",
      origin: "bottom",
      reset: true,
    });
    ScrollReveal().reveal(".my-face", {
      delay: 100,
      duration: 2000,
      scale: 2.5,
      reset: true,
    });
  }
}
