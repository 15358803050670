<template>
  <section class="hero is-light is-bold">
    <div class="hero-body container is-max-desktop">
      <p class="title"><i class="fas fa-bookmark"></i> Publications</p>
      <div class="has-text-left">
        <p>
          R. Chotkan, J. Decouchant and J. Pouwelse, "Distributed Attestation
          Revocation in Self-Sovereign Identity,"
          <i>2022 IEEE 47th Conference on Local Computer Networks (LCN)</i>,
          2022, pp. 414-421, doi: 10.1109/LCN53696.2022.9843323.
        </p>
        <br />
        <div class="is-divider"></div>
        <p>
          R. Chotkan, J. Decouchant and J. Pouwelse, "Unstoppable DAOs for Web3
          Disruption,"
          <i
            >2022 3rd International Workshop on Distributed Infrastructure for
            Common Good (DICG 2022)</i
          >, 2022.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Publications-component",
};
</script>

<style scoped></style>
