import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import Vue2TouchEvents from "vue2-touch-events";
import VueScrollTo from "vue-scrollto";
import vuetify from "@/plugins/vuetify";
import "buefy/dist/buefy.css";
import "bulma/bulma.sass";
import "bulma/css/bulma.css";
import "animate.css/animate.css";
import "bulma-extensions/bulma-timeline/dist/css/bulma-timeline.min.css";
import "bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css";

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(Vue2TouchEvents);
Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
