import { render, staticRenderFns } from "./Scroller.vue?vue&type=template&id=3b18d914&scoped=true&"
import script from "./Scroller.vue?vue&type=script&lang=ts&"
export * from "./Scroller.vue?vue&type=script&lang=ts&"
import style0 from "./Scroller.vue?vue&type=style&index=0&id=3b18d914&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b18d914",
  null
  
)

export default component.exports