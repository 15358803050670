<template>
  <div class="home">
    <Intro
      :construction="false"
      :menuEnabled="true"
      :disabledParticles="false"
    />
    <PublicationsComponent></PublicationsComponent>
    <WhoAmI2></WhoAmI2>
    <Timeline2 />
    <b-progress
      class="progress is-hidden-mobile"
      type="is-danger"
      :value="progressValue"
    />
  </div>
</template>

<script>
import Intro from "@/components/Intro.vue";
import Timeline2 from "@/components/timeline/Timeline2";
import WhoAmI2 from "@/components/whoami/WhoAmi2";
import PublicationsComponent from "@/components/publications/Publications";

export default {
  name: "home-page",
  components: {
    PublicationsComponent,
    WhoAmI2,
    Intro,
    Timeline2,
  },
  methods: {
    async handleScroll() {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      this.progressValue = (scrollTop / scrollHeight) * 170;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      progressValue: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: fixed;
  right: 0;
  top: 0;
  width: 100vh;

  transform: rotate(-90deg) translate(0, -100%);
  transform-origin: 100% 0;
}
</style>
